import ImageOne from '../images/house-1.jpg';
import ImageTwo from '../images/house-2.jpg';
import ImageThree from '../images/house-3.jpg';
import ImageFour from '../images/house-4.jpg';

export const SliderData = [
    {
        title: 'Luxary Villa in Sheffield',
        path: '/homes',
        label: 'View Home',
        image: ImageOne,
        alt: 'House'
    },

    {
        title: 'Luxary Apartment in Sheffield',
        path: '/homes',
        label: 'View Home',
        image: ImageTwo,
        alt: 'House'
    },

    {
        title: 'Lovely Home in Sheffield',
        path: '/homes',
        label: 'View Home',
        image: ImageThree,
        alt: 'House'
    },
    {
        title: 'Comfortable Villa in Sheffield',
        path: '/homes',
        label: 'View Home',
        image: ImageFour,
        alt: 'House'
    },

]