import React, { useState } from 'react'
import ContactCon from '../components/ContactCon'
import DropDown from '../components/DropDown'
import Navbar from '../components/Navbar'
import { FooterContainer } from '../container/footer'
import GlobalStyle from '../globalStyle'

const Contact = () => {
    const [isOpen, setIsOpen] = useState(false)
    
    const Toggle = () => {
      setIsOpen(!isOpen)
    }
  return (
    <>
         <GlobalStyle />
     <Navbar toggle={Toggle}></Navbar>
    <DropDown toggle={Toggle} isOpen={isOpen}/>
<ContactCon></ContactCon>
    <FooterContainer/>
    </>
  )
}

export default Contact
