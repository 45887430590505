import styled from "styled-components";


export const Container = styled.div`
padding: 80px 60px;
background: radial-gradient(circle,rgb(208 208 208) 0%,rgb(160 156 173) 100%);

`

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
max-width: 1000px;
margin: 0 auto;
`

export const Row = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
grid-gap: 60px;


@media (max-width: 1000px){
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}
`

export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
margin-left: 60px; 
`

export const Link = styled.div`
margin-bottom: 20px;
color: white;
font-size: 18px;
text-decoration: none;
cursor: pointer;


&:hover{
    color: #ff9c00;
    transition: 200ms ease-in
}
`

export const Title = styled.div`
font-size: 24px;
margin-bottom: 40px;
font-weight: bold;
cursor: pointer;
color: white;
`