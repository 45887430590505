import React from 'react'
import styled, {css }from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { menuData } from '../data/MenuData'
import { Button } from './Button'
import {FaBars} from 'react-icons/fa'
import logo from '../images/logomain.jpeg'

const Nav = styled.nav`
height: 60px;
display:flex;
justify-content: space-between;
padding: 1rem 2rem;
z-index: 100;
positon:fixed;
width:100%;
background: #d9d9d9;
`
const NavLink = css`

display:flex;
align-items; center;
padding: 0 1rem;
height: 100%;
cursor: pointer;
text-decoration: none;
color: #393939;
`
const Logo = styled(Link)`
${NavLink};
color: black;
font-size: 20px;


img{
    width: 100px;
    height: 100px;
    margin: -40px 0px;
}
`

const MenuBars = styled(FaBars)`
display:none;

@media screen and (max-width: 768px){
    display:block;
    cursor: pointer;
    position: absolute;
    height: 40px;
    width: 40px; 
    top: 0;
    right:0;
    transform: translate(-50%, 25%)
}
`

const NavMenu = styled.div`
display: flex;
align-items: center;
margin-right: 20px;

@media screen and (max-width: 768px){
    display:none;
}
`
const NavmenuLinks = styled(Link)`
${NavLink};
backgronund:#000;
`

const NavBtn = styled.div`
display:flex;
align-items:centered;
margin-right: 24px;

@media screen and (max-width: 768px){
    display:none;
}
`
const Navbar = ({toggle}) => {
  return (
    <Nav>
        <Logo to ="/"><img src={logo}/> Beyond Imaginations Holdings Limited</Logo>
        <MenuBars onClick={toggle}></MenuBars>
        <NavMenu>
        {
            menuData.map((item, index) => (
               
               <NavmenuLinks to={item.link} key={index}>
                    {item.title}
                </NavmenuLinks>
            ))
        }
        </NavMenu>
    </Nav>
  )
}

export default Navbar
