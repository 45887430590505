import React from 'react'
import { Routes, Route} from 'react-router-dom'
import About from './pages/About';
import Contact from './pages/Contact';
import Homepage from './pages/HomePage';



function App() {

  
  return (
   <>
   
    <Routes>
    <Route path="/" element={<Homepage/>} exact/>
    <Route path="/About" element={<About/>} exact/>
    <Route path="/Contact" element={<Contact/>} exact/>
    </Routes>
   
   
   
     
     </>
  );
}

export default App;