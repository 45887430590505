import React, {useState} from 'react';
import DropDown from '../components/DropDown'
import Hero from '../components/Hero'
import InfoSection from '../components/InfoSection'
import Navbar from '../components/Navbar'
import { FooterContainer } from '../container/footer'
import { InfoData, InfoDataTwo } from '../data/infoData'
import { SliderData } from '../data/sliderData'
import GlobalStyle from '../globalStyle'

function Homepage() {
    const [isOpen, setIsOpen] = useState(false)

    const Toggle = () => {
      setIsOpen(!isOpen)
    }
  return (
      <>
    <GlobalStyle />
    <Navbar toggle={Toggle}></Navbar>
    <DropDown toggle={Toggle} isOpen={isOpen}/>
    <Hero slides={SliderData }/>
     <InfoSection {...InfoData}{...InfoDataTwo}/>
    <FooterContainer/>
    </>
  )
}

export default Homepage
