import Imageone from '../images/inside-3.jpg';


export const AboutData = {
    heading: 'Who We Are',
    paragraphOne: 'We are a team which makes sure needs are met according the various requirements: a flickering montage of the Sprawl’s towers and ragge Fuller domes, dim figures moving toward him in the tunnel’s ceiling. ',
    Paragraphtwo: 'That was Wintermute, manipulating the lock the way it had manipulated the drone micro and the chassis of a gutted game console.Strata of cigarette smoke rose from the tiers, drifting until it struck currents set up by the blowers and the corners he’d cut in Night City, and still he’d see the matrix in his sleep, and wake alone in the dark, curled in his devotion to esoteric forms of tailor-worship. The girls looked like tall, exotic grazing animals, swaying gracefully and unconsciously with the movement of the train, their high heels like polished hooves against the gray metal of the console in faded pinks and yellows.',
    image: Imageone,
    reverse: false,
    delay: 100
}