import React from 'react'
import styled from 'styled-components'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {AiOutlineMail} from 'react-icons/ai'
import img from '../images/contactback2.jpg'
const Section = styled.section`
position:relative;
min-height:100vh;
padding: 50px 100px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
background: url(${img});
background-size: cover;


@media screen and (max-width: 991px){
padding: 50px;
}
`
const Content = styled.div`
max-width: 800px;
text-align: center;

h2{
    font-size: 36px;
    font-weight: 500;
    color: #fff;
}

p{
    color: #fff;
    font-weight:300;
}
`
const Container = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-top: 30px;
@media screen and (max-width: 991px){
flex-direction: column;
}

`
const ContactInfo = styled.div`
width: 50%;
display: flex;
flex-direction: column;


@media screen and (max-width: 991px){
    margin-bottom: 40px;
    width: 100%;
}
`
const Box = styled.div`
position: relative;
padding: 20px 0;
display: flex;
`
const PhoneIcon = styled(BsFillTelephoneFill)`
display: flex;
cursor: pointer;
height: 20px;
width: 20px; 
justify-content: center;
align-items: center;
font-size: 22px;
color: #fff;
`
const EmailIcon = styled(AiOutlineMail)`
display: flex;
cursor: pointer;
height: 20px;
width: 20px; 
justify-content: center;
align-items: center;
font-size: 22px;
color: #fff;
`
const ContactForm = styled.div`
width: 40%;
padding: 40px;
background: #fff;


h2{
    font-size: 30px;
    color: #333;
    font-weight: 500;
}
`
const Text = styled.div`
display: flex;
margin-left: 20px;
font-size: 16px;
color: #fff;
flex-direction: column;
font-weight: 300;

h3{
    font-weight: 500;
    color: #00bcd4;
}
`
const InputBox = styled.div`
position: relative;
width: 100%;
margin-top: 10px;

input, textarea{
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    border-bottom: 2px solid #333;
    outline: none;
    resize: none;
}

span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;

}
input[type="submit"]{
    width: 100px;
    background: #00bcd4;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;

}

`


const ContactCon = () => {


  return (
    <Section>
      <Content>
        <h2>Contact Us</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nulla est, interdum at velit sed, interdum vehicula est. Quisque a enim est. Nunc turpis tellus.</p>
      </Content>
<Container>
<ContactInfo>
<Box>
<PhoneIcon />
<Text>
    <h3>Phone</h3>
    <p>+44-7932-533-978</p>
</Text>
</Box>
<Box>
    <EmailIcon></EmailIcon>
        <Text>
            <h3>Email</h3>
                <p>admin@beyondimaginations.co.uk</p>
            </Text>
        </Box>
    </ContactInfo>
        <ContactForm>
    <form>
        <h2>Send Message</h2>
        <InputBox>
        <input type = "text" name = "" required="required"/>
        <span>Full Name</span>
        </InputBox>
        <InputBox>
        <input type = "text" name = "" required="required"/>
        <span>Email</span>
        </InputBox>
        <InputBox>
        <textarea></textarea>
        <span>Type your Message</span>
        </InputBox>
        <InputBox>
        <input type = "submit"/>
        
        </InputBox>
    </form>
        </ContactForm>
        </Container>
    </Section>
  )
}

export default ContactCon
