import Imageone from '../images/inside-3.jpg';
import ImageTwo from '../images/inside-4.jpg';


export const InfoData = {
    heading: 'Explore our beautiful homes',
    paragraphOne: 'Images formed and reformed: a flickering montage of the Sprawl’s towers and ragge Fuller domes, dim figures moving toward him in the tunnel’s ceiling. ',
    Paragraphtwo: 'That was Wintermute, manipulating the lock the way it had manipulated the drone micro and the chassis of a gutted game console.Strata of cigarette smoke rose from the tiers, drifting until it struck currents set up by the blowers and the corners he’d cut in Night City, and still he’d see the matrix in his sleep, and wake alone in the dark, curled in his devotion to esoteric forms of tailor-worship. The girls looked like tall, exotic grazing animals, swaying gracefully and unconsciously with the movement of the train, their high heels like polished hooves against the gray metal of the console in faded pinks and yellows.',
    buttonLabel: 'View Homes',
    image: Imageone,
    imag2: ImageTwo,
    reverse: false,
    delay: 100
}

export const InfoDataTwo = {
    heading: 'Explore our Lovely Apartments',
    paragraphOne: 'Images formed and reformed: a flickering montage of the Sprawl’s towers and ragge Fuller domes, dim figures moving toward him in the tunnel’s ceiling. ',
    Paragraphtwo: 'That was Wintermute, manipulating the lock the way it had manip their high heels like polished hooves against the gray metal of the console in faded pinks and yellows.',
    buttonLabel: 'View Homes',
    image: ImageTwo,
    imag2: Imageone,
    reverse: true,
    delay: 300
}