import React, { useState } from 'react'
import DropDown from '../components/DropDown'
import Navbar from '../components/Navbar'
import GlobalStyle from '../globalStyle'
import AboutComp from '../components/AboutComp'
import {AboutData} from '../data/AboutData'
import { FooterContainer } from '../container/footer'
function About() {
        const [isOpen, setIsOpen] = useState(false)
    
        const Toggle = () => {
          setIsOpen(!isOpen)
        }
  return (
    <>
     <GlobalStyle />
     <Navbar toggle={Toggle}></Navbar>
    <DropDown toggle={Toggle} isOpen={isOpen}/>
    <AboutComp {...AboutData}/>
    <FooterContainer/>
    </>
  )
}

export default About
